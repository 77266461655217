import React from "react";
import styled from "styled-components";

import { Seo } from "../../../component/SEO";
import { Favicons } from "../../../component/Favicons";
import Header from "../../../component/Header";
import TaxidFooter from "../../../component/TaxidFooter";
import Footer from "../../../component/Footer";

const { NEXT_PUBLIC_REACT_APP_SKIN } = process.env;

const StyledLayout = styled.main``;

function Layout({ children }) {

	return (
		<StyledLayout
			className={
				NEXT_PUBLIC_REACT_APP_SKIN === "taxid" ||
				NEXT_PUBLIC_REACT_APP_SKIN === "irs" 
					? NEXT_PUBLIC_REACT_APP_SKIN 
					: NEXT_PUBLIC_REACT_APP_SKIN === "irsein" ? "irs" : NEXT_PUBLIC_REACT_APP_SKIN === "taxid-ein" ? "taxid" :""
			}
		>
      <Seo title={`${process.env.NEXT_PUBLIC_REACT_APP_SKIN === "taxid" || process.env.NEXT_PUBLIC_REACT_APP_SKIN === "taxid-ein" ? "Taxid" : "Irs"} | Home`} description="" />
      <Favicons />
			<Header />
			{children}
      {NEXT_PUBLIC_REACT_APP_SKIN === "taxid" ? <TaxidFooter /> : <Footer />}
		</StyledLayout>
		
	);
}

export default Layout;
